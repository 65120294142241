import { RootContextType, useRootContext } from "../../../RootLayout.tsx";
import { useContext } from "react";
import { Wallet, WalletType } from "../../../services/openAPI/client/index.ts";
import {
  color,
  Divider,
  formatCryptoQuantity,
  formatWalletAddress,
  Icons,
  Typography,
} from "@bakkt/bakkt-ui-components";
import {
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  SvgIcon,
  Tooltip,
  Unstable_Grid2 as Grid,
  Container,
  Box,
  Chip,
  Stack,
} from "@mui/material";
import { checkWarmWallet, getAvailableAndPendingBalances, getSVGStringForTicker } from "../../../utils/dataUtils.ts";
import { CryptoTickerEnum } from "../../../utils/CryptoIconsMap.ts";
import { isREL009PublicFeature } from "../../../config/firebaseConfig.ts";
import { AssetSVGIcon } from "../../../components/customSVG/AssetSVGIcon.tsx";
import WithdrawSourceWalletBalance from "./WithdrawSourceWalletBalance.tsx";
import { withdrawContext } from "./WithdrawContext.tsx";

function WithdrawSourceWallet() {
  const withdrawCtx = useContext(withdrawContext);
  const {
    selectedWallet,
    selectedWalletType,
    selectedWalletId,
    selectedAsset,
    handleWalletType,
    handleChangeAsset,
    handleChangeWallet,
    isWarmBalance,
  } = withdrawCtx;

  const { orgDataCache, selectedOrg, priceFeed, assets } = useRootContext() as RootContextType;
  const wallets = [...orgDataCache.wallets, ...orgDataCache.omnibusWallets];

  const disableOmnibus = orgDataCache.omnibusWallets.length === 0;

  function getDisplayTextSource(wallet: Wallet | null, isSelected?: boolean) {
    const balances = getAvailableAndPendingBalances(wallet, priceFeed, assets);
    const cryptoBalance = balances.availableBalanceCrypto || 0;
    if (!wallet) {
      return;
    }

    const selectedAsset = assets.find((asset) => asset.ticker === wallet.assetTicker);
    return (
      <Grid container direction={"column"}>
        <Grid container alignItems={"center"} sx={{ pb: 0 }}>
          <SvgIcon
            component={() =>
              AssetSVGIcon({
                svgString: getSVGStringForTicker(assets, wallet.assetTicker as string),
                title: wallet.assetTicker,
                sx: { width: 32, height: 32, pr: 1 },
              })
            }
            inheritViewBox
          />
          <Typography variant="h5">{selectedAsset?.name}</Typography>
          <Typography variant={"subtitle1"}> {`(${selectedOrg.name})`}</Typography>
        </Grid>
        <Grid container alignItems={"center"} sx={{ pl: 4 }}>
          <Typography variant={"body1"}>{`ID: ${formatWalletAddress(wallet?.address || "")}`}</Typography>
        </Grid>
        {!isSelected && (
          <Grid container alignItems={"center"} sx={{ pl: 4 }}>
            <Typography variant={"body1"}>{`Balance: ${formatCryptoQuantity(
              cryptoBalance as number,
              wallet?.assetTicker,
            )} ${wallet?.assetTicker}`}</Typography>
          </Grid>
        )}
      </Grid>
    );
  }

  return (
    <>
      <Typography variant="h5" sx={{ mb: 1 }}>
        Source
      </Typography>
      <Container disableGutters maxWidth={"lg"}>
        <Grid container direction={"row"} columnGap={4}>
          <Grid xs={6}>
            {isREL009PublicFeature && (
              <FormControl variant="standard" required>
                <RadioGroup name="wallet-type" onChange={handleWalletType} value={selectedWalletType} row>
                  <FormControlLabel value={WalletType.Custody} control={<Radio />} label="Segregated Wallet" />
                  <Stack direction={"row"} alignContent={"flex-end"} alignItems={"flex-end"} sx={{ p: 0, m: 0 }}>
                    <FormControlLabel
                      value={WalletType.Trading}
                      control={<Radio />}
                      label="Omnibus Wallet"
                      sx={{ mr: 0 }}
                      disabled={disableOmnibus}
                    />
                    {disableOmnibus && (
                      <Tooltip
                        title={
                          <Typography variant="body2">
                            Please activate omnibus in the dashboard in order to create omnibus wallets.
                          </Typography>
                        }
                        placement="top"
                      >
                        <Box>
                          <SvgIcon
                            component={Icons.InformationIcon}
                            sx={{ width: 22, height: 22, color: color.text.disabled }}
                          />
                        </Box>
                      </Tooltip>
                    )}
                  </Stack>
                </RadioGroup>
              </FormControl>
            )}
          </Grid>
          <Grid xs={6}>
            {selectedWalletType !== WalletType.Trading ? (
              <>
                <FormControl variant="standard" sx={{ width: "100%", mb: 3 }} required>
                  <InputLabel id="select-asset">Asset</InputLabel>
                  <Select
                    name="assetTicker"
                    value={selectedAsset ? (selectedAsset as any) : ""}
                    label="asset"
                    onChange={handleChangeAsset}
                  >
                    {assets.map((asset, index) => (
                      <MenuItem key={index} value={asset.ticker} disabled={asset.ticker === CryptoTickerEnum.ETC}>
                        {asset.ticker === CryptoTickerEnum.ETC ? (
                          <>
                            <Grid container alignItems={"center"} sx={{ p: 1, pb: 0 }}>
                              <SvgIcon
                                component={() =>
                                  AssetSVGIcon({
                                    svgString: asset.iconSVG,
                                    title: asset.ticker,
                                    sx: { width: 32, height: 32, pr: 1 },
                                  })
                                }
                                inheritViewBox
                              />
                              <Typography sx={{ fontWeight: 600, mr: 1 }}>{asset.name}</Typography>
                              <Typography variant={"body2"} sx={{ mr: 1 }}>
                                {asset.ticker}
                              </Typography>
                              <Chip key={asset.ticker} label={"Contact Support"} />
                            </Grid>
                          </>
                        ) : (
                          <>
                            <Grid container alignItems={"center"} sx={{ p: 1, pb: 0 }}>
                              <SvgIcon
                                component={() =>
                                  AssetSVGIcon({
                                    svgString: asset.iconSVG,
                                    title: asset.ticker,
                                    sx: { width: 32, height: 32, pr: 1 },
                                  })
                                }
                                inheritViewBox
                              />
                              <Typography sx={{ fontWeight: 600, mr: 1 }}>{asset.name}</Typography>
                              <Typography variant={"body2"}>{asset.ticker}</Typography>
                            </Grid>
                          </>
                        )}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                <FormControl variant="standard" sx={{ width: "100%" }} required>
                  <InputLabel id="select-wallet">Wallet</InputLabel>
                  <Select
                    name="fromWalletId"
                    value={selectedWalletId ? (selectedWalletId as any) : ""}
                    renderValue={() => `${selectedWallet?.name}`}
                    label="Wallet"
                    onChange={handleChangeWallet}
                    MenuProps={{
                      sx: { maxHeight: 300 },
                    }}
                  >
                    {wallets
                      .filter(
                        (wallet) =>
                          wallet.assetTicker === selectedAsset &&
                          wallet.type === WalletType.Custody &&
                          checkWarmWallet(isWarmBalance as boolean, wallet.temperature as string),
                      )
                      .map((wallet) => (
                        <MenuItem key={wallet.walletId} value={wallet.walletId}>
                          <Box>
                            <Typography variant="h5">{wallet.name}</Typography>
                            <Typography variant={"body1"}>{`ID: ${wallet.walletId}`}</Typography>
                          </Box>
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </>
            ) : (
              <>
                <FormControl variant="standard" sx={{ width: "100%" }} required>
                  <InputLabel id="select-wallet">Source omnibus wallet</InputLabel>
                  <Select
                    name="fromWalletId"
                    value={selectedWallet?.walletId ? (selectedWallet?.walletId as any) : ""}
                    renderValue={() => getDisplayTextSource(selectedWallet || null, true)}
                    onChange={handleChangeWallet}
                    label="Wallet"
                    MenuProps={{
                      sx: { maxHeight: 300 },
                    }}
                  >
                    {wallets
                      .filter((wallet) => wallet.type === WalletType.Trading)
                      .map((wallet) => (
                        <MenuItem key={wallet.walletId} value={wallet.walletId}>
                          {getDisplayTextSource(wallet)}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </>
            )}
          </Grid>

          <Divider orientation="vertical" flexItem />
          <WithdrawSourceWalletBalance
            selectedWallet={selectedWallet}
            selectedWalletType={selectedWalletType}
            selectedWalletId={selectedWalletId}
          />
        </Grid>
      </Container>
    </>
  );
}

export default WithdrawSourceWallet;
