import { Box, Container, Stack, SvgIcon, Tooltip, useTheme } from "@mui/material";
import { innerGridContainer, subText, tableHeader, totalBalanceValue, wrapperContainer } from "./styles.tsx";
import { Unstable_Grid2 as Grid } from "@mui/material";
import { Divider, Icons, Paper, Typography, formatDollarAmountUsd } from "@bakkt/bakkt-ui-components";
import {
  calculateBalanceTotalsFromWallets,
  calculateOffExchangeTotalsFromWallets,
  checkWarmBalance,
} from "../../utils/dataUtils.ts";
import { NavLink } from "react-router-dom";
import { PriceInfo } from "../../services/openAPI/client";
import { RootContextType, useRootContext } from "../../RootLayout";
import { isREL009PublicFeature } from "../../config/firebaseConfig.ts";
import WalletBalanceContainer from "./balanceContainer/WalletBalanceContainer.tsx";
import { dashboardBalanceLabel } from "../../utils/customTypes.ts";

export default function Balances() {
  const theme = useTheme();
  const { selectedOrg } = useRootContext();
  const { orgDataCache, priceFeed, assets } = useRootContext() as RootContextType;
  const wallets = isREL009PublicFeature
    ? [...orgDataCache.wallets, ...orgDataCache.omnibusWallets]
    : [...orgDataCache.wallets];

  const hideBelowLarge = {
    display: "block",
    [theme.breakpoints.down("lg")]: {
      display: "none",
    },
  };

  const hideAboveLarge = {
    display: "block",
    [theme.breakpoints.up("lg")]: {
      display: "none",
    },
  };

  const balanceData = calculateBalanceTotalsFromWallets(wallets, priceFeed as PriceInfo[], assets);
  const isWarmBalance = checkWarmBalance(wallets, priceFeed as PriceInfo[], assets);
  const offExchangeBalances = calculateOffExchangeTotalsFromWallets(orgDataCache.offExchangeWallets, priceFeed, assets);

  const isOffExchangeBalanceViewEnabled =
    selectedOrg.isExchangeAccountEnabled && orgDataCache.offExchangeWallets.length > 0 && !isWarmBalance;

  return (
    <Container maxWidth="xl" disableGutters sx={wrapperContainer}>
      <Grid container spacing={2}>
        <Grid xs={9}>
          <Typography variant="h3" data-testid="balances">
            Balances
          </Typography>
          <Typography variant="body1" sx={subText}>
            For details on pending transactions, please visit the{" "}
            <NavLink to={`/transactions/${selectedOrg.id}`}>
              <Box component="span" sx={{ textDecoration: "underline", textDecorationSkipInk: "none" }}>
                Transactions log
              </Box>
            </NavLink>
          </Typography>
        </Grid>
      </Grid>

      <Paper>
        <Grid container xs={12}>
          <Grid xs={isOffExchangeBalanceViewEnabled ? 12 : 4}>
            <Grid container>
              <Container data-testid="balanceContainer" sx={innerGridContainer}>
                <Stack direction={"row"} alignContent={"center"} sx={{ p: 0, m: 0 }}>
                  <Typography variant="overline" data-testid="label" sx={tableHeader}>
                    Current Balance
                  </Typography>

                  <Tooltip
                    title={
                      <Typography variant="body2">
                        Current balance is the sum total of confirmed assets across all wallets. Bakkt waits for a
                        certain number of blocks to pass before a transaction is confirmed. Block confirmations vary
                        from chain to chain so please review our user guide to understand the confirmations required per
                        asset.
                      </Typography>
                    }
                    placement="top"
                  >
                    <Box>
                      <SvgIcon component={Icons.InformationIcon} sx={{ width: 25, height: 25 }} />
                    </Box>
                  </Tooltip>
                </Stack>

                <Typography variant="h3" data-testid="amount" sx={totalBalanceValue}>
                  {/* Update this value to be omnibus + custodial wallets */}
                  {formatDollarAmountUsd(balanceData.total + offExchangeBalances.totalBalance)}
                </Typography>

                {/*TODO: Coming back in Phase 2*/}
                {/*{balanceLabel === "Total Balance" && (*/}
                {/*  <div data-testid="percentChange">*/}
                {/*    <UpDownPill direction={"up"} text={"6.8%"} />*/}
                {/*    <span>Last 24 hours</span>*/}
                {/*  </div>*/}
                {/*)}*/}
              </Container>
            </Grid>
            {isOffExchangeBalanceViewEnabled && <Divider />}
          </Grid>
          {isWarmBalance && (
            <>
              {!isOffExchangeBalanceViewEnabled && <Divider orientation="vertical" flexItem />}
              <WalletBalanceContainer
                isREL009PublicFeature={isREL009PublicFeature}
                balanceData={balanceData?.warm}
                label={dashboardBalanceLabel.warm}
              />
              <Divider orientation="vertical" flexItem />
              <WalletBalanceContainer
                isREL009PublicFeature={isREL009PublicFeature}
                balanceData={balanceData?.cold}
                label={dashboardBalanceLabel.cold}
              />
            </>
          )}
          {!isWarmBalance && (
            <>
              {!isOffExchangeBalanceViewEnabled && <Divider orientation="vertical" flexItem />}
              <WalletBalanceContainer
                balanceData={balanceData?.segregatedCustody}
                isREL009PublicFeature={isREL009PublicFeature}
                label={dashboardBalanceLabel.segregated}
              />
            </>
          )}
          {selectedOrg.isExchangeAccountEnabled && orgDataCache.offExchangeWallets.length > 0 && (
            <>
              <Divider orientation="vertical" flexItem />
              <WalletBalanceContainer
                balanceData={offExchangeBalances?.totalBalance}
                label={dashboardBalanceLabel.offExchange}
              />
            </>
          )}
          {isREL009PublicFeature && orgDataCache.omnibusWallets.length > 0 && (
            <>
              <Divider orientation="vertical" flexItem />
              <WalletBalanceContainer balanceData={balanceData?.omnibusTrading} label={dashboardBalanceLabel.omnibus} />
            </>
          )}
        </Grid>
      </Paper>
    </Container>
  );
}
