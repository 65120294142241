import { Button, Dialog, DialogTitle } from "@bakkt/bakkt-ui-components";
import { DialogActions, DialogContent } from "@mui/material";
import { useState } from "react";
import { useNavigate, LoaderFunctionArgs, defer, useLoaderData, useLocation } from "react-router-dom";
import { RootContextType, useRootContext } from "../../RootLayout";
import { shouldUseMockData } from "../../utils/dataUtils";
import { fetchMockDataPromiseWithDelay, walletTransactions } from "../../services/mockData";
import { WalletTransactionService } from "../../services/serviceLoader";
import { PolicyActionActionEnum, WalletTransaction } from "../../services/openAPI/client";
import { isApprover, isPolicyDisabled } from "../../utils/permissionsUtil";
import ReviewTransferDetails from "./ReviewTransferDetails";
import { TransferFormData } from "./WalletTransfer";

const ReviewTransfer = () => {
  const { selectedOrg, userInfo } = useRootContext() as RootContextType;
  const navigate = useNavigate();
  const { state } = useLocation();
  const isApproved = state.approved;
  const policyItem = isApproved ? state.policyItem : state.data;
  const [open, setOpen] = useState(true);

  const { walletTransaction } = useLoaderData() as {
    walletTransaction: WalletTransaction;
  };

  const transferFormData: TransferFormData = {
    assetTicker: walletTransaction?.assetTicker || "",
    fromWalletId: walletTransaction.sourceId || null,
    quantity: walletTransaction.quantity || null,
    accountId: userInfo.accountId,
    organizationId: selectedOrg.id,
    destinationWalletId: walletTransaction.destinationId || null,
    createdOn: policyItem.timestamp,
    clientName: policyItem.client,
    orgName: policyItem.organization,
    requesterName: policyItem.requester,
  };

  const thirdActionSx = { selfAlign: "start", marginRight: "auto" };

  const handleClose = () => {
    setOpen(false);
    navigate(-1);
  };

  const handleDecline = () => {
    navigate(
      `/${selectedOrg.id}/${PolicyActionActionEnum.Deny.toLowerCase()}-transaction/${policyItem.policyActionId}`,
      {
        state: policyItem,
      },
    );
  };

  const handleCancel = () => {
    setOpen(false);
    navigate(-1);
  };

  const handleConfirm = () => {
    navigate(
      `/${selectedOrg.id}/${PolicyActionActionEnum.Approve.toLowerCase()}-transaction/${policyItem.policyActionId}`,
      { state: policyItem },
    );
  };

  const isDisabled = isPolicyDisabled(
    policyItem.approvalCount,
    policyItem?.consensusNumber,
    policyItem.policyInstanceStatus,
    policyItem.policySequenceStatus,
    isApproved,
  );

  return (
    <>
      <Dialog open={open} onClose={handleClose} maxWidth={"sm"} fullWidth={false}>
        <DialogTitle title={`Review Omnibus Transfer`} severity="warning">
          Review the request details below.
        </DialogTitle>
        <DialogContent>
          <ReviewTransferDetails transferRequest={transferFormData} />
        </DialogContent>
        <DialogActions>
          <Button variant={"outlined"} onClick={handleCancel} sx={thirdActionSx}>
            Go Back
          </Button>

          {isApprover(userInfo, Number(selectedOrg.id)) && (
            <>
              <Button variant={"outlined"} onClick={handleDecline} disabled={isDisabled} sx={{ mr: 1 }}>
                Decline
              </Button>

              <Button variant={"contained"} onClick={handleConfirm} disabled={isDisabled} autoFocus>
                Approve
              </Button>
            </>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ReviewTransfer;

export async function loader({ params }: LoaderFunctionArgs) {
  const walletTransactionId = Number(params.walletTransactionId);

  const transactionRequestItem = shouldUseMockData
    ? ((await fetchMockDataPromiseWithDelay(walletTransactions[0], 300)) as WalletTransaction)
    : await (WalletTransactionService.getWalletTransactionById(walletTransactionId) as WalletTransaction);

  return defer({
    walletTransaction: transactionRequestItem,
  });
}
