import { Box } from "@mui/material";
import { Link as RouterLink, useLocation } from "react-router-dom";
import { PolicyActionDetails, PolicyInstanceRequestTypeEnum, PolicyStatusEnum } from "../../services/openAPI/client";
import { getRouteByRequestCategory } from "../../utils/dataUtils";
import { color } from "@bakkt/bakkt-ui-components";

interface PendingApprovalLinkProps {
  policyItem: PolicyActionDetails;
  linkStyle?: object;
  linkState?: object;
}

const PendingApprovalLink = ({ policyItem, linkStyle, linkState }: PendingApprovalLinkProps) => {
  const location = useLocation();

  const policyStatus = (policyItem: PolicyActionDetails) => {
    return (Number(policyItem.approvalCount) < 1 &&
      Number(policyItem.approvalCount) < Number(policyItem.consensusNumber)) ||
      policyItem.policyInstanceRequestType === PolicyInstanceRequestTypeEnum.Settlement
      ? policyItem.policySequenceStatus
      : "PENDING APPROVAL";
  };

  return (
    <>
      {policyItem.policySequenceStatus != PolicyStatusEnum.Cancelled ? (
        <RouterLink
          to={`${location.pathname}/review-${getRouteByRequestCategory(policyItem)}/${
            JSON.parse(String(policyItem.policyInstancePayload)).entityId
          }`}
          style={{
            ...linkStyle,
            color: policyStatus(policyItem) === "PENDING APPROVAL" ? color.engagingOrange.dark : color.techBlack[700],
          }}
          state={linkState}
        >
          <Box component="span" sx={{ textDecoration: "underline" }}>
            {policyStatus(policyItem)}
          </Box>
        </RouterLink>
      ) : (
        <Box component="span">{policyItem.policySequenceStatus}</Box>
      )}
    </>
  );
};

export default PendingApprovalLink;
