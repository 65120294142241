import { Typography } from "@bakkt/bakkt-ui-components";
import { FormControl, FormControlLabel, Radio, RadioGroup, Unstable_Grid2 as Grid } from "@mui/material";
import { useContext } from "react";
import { ManageCollateralContext } from "./CollateralContext";
import { WalletTransactionTypeEnum } from "../../../services/openAPI/client";

const SelectCollateralAction = () => {
  const context = useContext(ManageCollateralContext);
  const { collateralAction, handleCollateralAction } = context;

  return (
    <>
      <Typography variant="h5" sx={{ mb: 1 }}>
        Action
      </Typography>
      <Grid container direction={"column"} justifyContent={"space-between"}>
        <Grid>
          <FormControl>
            <RadioGroup
              row
              aria-labelledby="owner-label"
              name="owner"
              onChange={handleCollateralAction}
              value={collateralAction}
            >
              <FormControlLabel
                value={WalletTransactionTypeEnum.AddCollateralWithdraw}
                control={<Radio />}
                label={"Add Collateral"}
              />
              <FormControlLabel
                value={WalletTransactionTypeEnum.RemoveCollateralWithdraw}
                control={<Radio />}
                label={"Remove Collateral"}
              />
            </RadioGroup>
          </FormControl>
        </Grid>
      </Grid>
    </>
  );
};

export default SelectCollateralAction;
