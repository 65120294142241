import { Unstable_Grid2 as Grid } from "@mui/material";
import { WalletTransaction } from "../../../services/openAPI/client";
import { Typography } from "@bakkt/bakkt-ui-components";
import { useTheme } from "@mui/material/styles";
import { SettlementFormData } from "./ReviewSettlement.tsx";
import { SettlementLineItem } from "./SettlementLineItem.tsx";

interface SettlementReviewProps {
  settlementFormData: SettlementFormData;
}

export default function ReviewSettlementDetails({ settlementFormData }: SettlementReviewProps) {
  const theme = useTheme();

  function buildSettlementLineItem(
    settlementFormData: SettlementFormData,
    transaction: WalletTransaction,
  ): SettlementLineItem {
    return {
      accountId: settlementFormData.accountId,
      organizationId: settlementFormData.organizationId,
      clientName: settlementFormData.clientName,
      orgName: settlementFormData.orgName,
      requesterName: settlementFormData.requesterName,
      createdOn: settlementFormData.createdOn,
      settlementTransaction: transaction,
    };
  }

  const shouldMapOverSettlements = settlementFormData && settlementFormData?.settlementTransactions.length > 0;

  return (
    <Grid container spacing={1}>
      <Grid xs={12}>
        <Typography sx={{ fontWeight: 600, mb: 1 }}>Change</Typography>
        <Grid>
          {shouldMapOverSettlements &&
            settlementFormData?.settlementTransactions.map((transaction: WalletTransaction, index: number) => (
              <SettlementLineItem
                key={index}
                settlementLineItem={buildSettlementLineItem(settlementFormData, transaction)}
              />
            ))}
        </Grid>
      </Grid>
    </Grid>
  );
}
