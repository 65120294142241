import { AlertColor } from "@mui/material";

export type AlertDisplay = {
  severity: AlertColor;
  messageHeader?: string;
  message?: string;
};

export enum destinationType {
  External = "EXTERNAL",
  Internal = "INTERNAL",
  Trading = "TRADING",
}

export enum collateralActionType {
  add = "ADD_COLLATERAL_WITHDRAW",
  remove = "REMOVE_COLLATERAL_WITHDRAW",
}

export enum withdrawToType {
  Wallet = "WALLET",
  Allowlist = "ALLOWLIST",
}

export interface ActionResponse {
  success: boolean;
  title?: string;
  message?: string;
  data?: string;
}

export enum EnvType {
  Mainnet = "prod",
  Testnet = "testnet",
}

export type AddressTicker = {
  address: string;
  assetTicker: string;
};

export enum dashboardBalanceLabel {
  cold = "COLD",
  warm = "WARM",
  segregated = "SEGREGATED",
  offExchange = "OFF EXCHANGE",
  omnibus = "OMNIBUS",
}
