import { useState } from "react";
import { Wallet, WalletType } from "../../../services/openAPI/client";
import { color, formatWalletAddress, Icons, Typography } from "@bakkt/bakkt-ui-components";
import { ClickAwayListener, IconButton, SvgIcon, Tooltip, Unstable_Grid2 as Grid, Box } from "@mui/material";
import { WalletBalanceAvailableOnly } from "../balances/WalletBalanceAvailableOnly";

interface WithdrawSourceWalletBalanceProps {
  selectedWallet: Wallet | null | undefined;
  selectedWalletType: string | undefined;
  selectedWalletId: number | null | undefined;
}

function WithdrawSourceWalletBalance({
  selectedWallet,
  selectedWalletType,
  selectedWalletId,
}: WithdrawSourceWalletBalanceProps) {
  const [showCopyAddressConfirm, setShowCopyAddressConfirm] = useState(false);

  const handleTooltipClose = () => {
    setShowCopyAddressConfirm(false);
  };

  const copyAddress = () => {
    navigator.clipboard.writeText(selectedWallet?.address as string);
    setShowCopyAddressConfirm(true);
  };

  const bodyHeadingSx = {
    color: color.text.secondary,
    fontWeight: "bold",
  };
  return (
    <Grid container direction={"column"}>
      {selectedWallet ? (
        <>
          {selectedWalletType === WalletType.Custody && (
            <Box sx={{ mb: 3 }}>
              <Typography variant="body2" sx={bodyHeadingSx}>
                {selectedWallet.name} ADDRESS
              </Typography>
              {formatWalletAddress(selectedWallet.address as string)}
              <ClickAwayListener onClickAway={handleTooltipClose}>
                <Tooltip
                  onClose={handleTooltipClose}
                  open={showCopyAddressConfirm}
                  disableFocusListener
                  disableHoverListener
                  disableTouchListener
                  placement="top"
                  title="Address Copied"
                >
                  <IconButton sx={{ m: 0, p: 0 }} onClick={copyAddress} disableRipple disableFocusRipple>
                    <SvgIcon
                      component={Icons.CopyIcon}
                      inheritViewBox
                      sx={{ width: "15px", height: "15px", ml: 1, mr: 1.2 }}
                    />
                  </IconButton>
                </Tooltip>
              </ClickAwayListener>
              <IconButton sx={{ m: 0, p: 0 }} disableRipple={true}>
                <SvgIcon component={Icons.GoToIcon} inheritViewBox sx={{ width: "16px", height: "16px" }} />
              </IconButton>
            </Box>
          )}
          {selectedWalletId && <WalletBalanceAvailableOnly walletId={selectedWalletId.toString() as string} />}
        </>
      ) : (
        <>
          <Typography variant="body2" sx={bodyHeadingSx}>
            AMOUNT AVAILABLE
          </Typography>
          <Typography variant="h5">Wallet Not Selected</Typography>
          <Typography variant="subtitle1">$0.00</Typography>
        </>
      )}
    </Grid>
  );
}

export default WithdrawSourceWalletBalance;
