import { ClickAwayListener, IconButton, SvgIcon, Tooltip, Unstable_Grid2 as Grid, Box, Stack } from "@mui/material";
import {
  AvailableAndPendingBalanceSummary,
  getAvailableAndPendingBalances,
  getDecimalLength,
  getPriceByAssetQty,
  getSVGStringForTicker,
  getScanLink,
} from "../../../utils/dataUtils";
import { Account, Wallet, WalletTransactionTypeEnum } from "../../../services/openAPI/client";
import { useState } from "react";
import { useRootContext } from "../../../RootLayout";
import { CryptoTickerEnum, iconsMap } from "../../../utils/CryptoIconsMap";
import {
  Icons,
  Typography,
  formatDollarAmountUsd,
  formatCryptoQuantity,
  formatWalletAddress,
  Skeleton,
} from "@bakkt/bakkt-ui-components";
import { useTheme } from "@mui/material/styles";
import QuantityDisplay from "../../../components/quantityDisplay/QuantityDisplay";
import { AssetSVGIcon } from "../../../components/customSVG/AssetSVGIcon";
import { CollateralMinimal } from "./CollateralContext";
import useGetNetworkFeeEstimation from "../../../hooks/useGetNetworkFeeEstimation";

interface ReviewCollateralProps {
  collateralRequest: Partial<CollateralMinimal>;
}

const ReviewCollateralDetails = ({ collateralRequest }: ReviewCollateralProps) => {
  const { orgDataCache, userInfo, selectedOrg, priceFeed, assets, networks } = useRootContext();
  const wallets: Wallet[] = [...orgDataCache.wallets, ...orgDataCache.offExchangeWallets];
  const account: Account = orgDataCache.account;
  const selectedWallet = wallets?.find((w) =>
    collateralRequest.action === WalletTransactionTypeEnum.AddCollateralWithdraw
      ? w.walletId === collateralRequest.fromWalletId
      : w.walletId === collateralRequest.toWalletId,
  ) as Wallet;

  const offExchangeWallet = wallets?.find((w) => w.walletId === collateralRequest.offExchangeWalletId);

  const isPolicyCreated = collateralRequest.createdOn ? true : false;

  const { isLoading, isERC20, networkFeeEstimation, networkFeeTicker, feeWallet } = useGetNetworkFeeEstimation(
    collateralRequest,
    selectedWallet,
    offExchangeWallet,
    wallets,
  );

  const [showWalletAddressConfirm, setShowWalletAddressConfirm] = useState(false);

  const balances = getWalletBalances();

  function getWalletBalances(): AvailableAndPendingBalanceSummary {
    return getAvailableAndPendingBalances(offExchangeWallet, priceFeed, assets);
  }
  const availableBalance =
    balances.availableBalanceCrypto + (isPolicyCreated ? Number(collateralRequest?.quantity) : 0);

  const collateralBalance = offExchangeWallet?.lockedBalance;

  const getUpdatedBalance = (a: number, b: number) => {
    if (collateralRequest.action === WalletTransactionTypeEnum.AddCollateralWithdraw) {
      return a + b;
    } else if (collateralRequest.action === WalletTransactionTypeEnum.RemoveCollateralWithdraw) {
      return a - b;
    }
  };

  const updatedCollateralBalance = getUpdatedBalance(collateralBalance || 0, Number(collateralRequest.quantity) || 0);
  const updatedBalance = getUpdatedBalance(availableBalance || 0, Number(collateralRequest.quantity));

  const copyWalletAddress = (address: string) => {
    setShowWalletAddressConfirm(true);
    navigator.clipboard.writeText(address || "");
  };
  const goToIconStyle = { width: 14, height: 14 };
  const copyIconStyle = { width: 15, height: 15, ml: 1, mr: 1.2 };
  const scanWalletAddress = getScanLink(
    selectedWallet?.assetTicker || "",
    selectedWallet?.address || "",
    assets,
    networks,
  );

  const theme = useTheme();

  function segregatedWalletField() {
    return (
      <Grid>
        <Grid container justifyContent={"flex-end"}>
          <Typography variant="subtitle1">
            <SvgIcon
              component={() =>
                AssetSVGIcon({
                  svgString: getSVGStringForTicker(assets, collateralRequest.assetTicker as string),
                  title: collateralRequest.assetTicker as string,
                  sx: { width: 16, height: 16, mr: 1, mb: -0.3 },
                })
              }
              inheritViewBox
            />

            {selectedWallet?.name}
          </Typography>
        </Grid>

        <Grid direction="row" sx={{ textAlign: "right" }}>
          {formatWalletAddress(selectedWallet.address as string)}
          <ClickAwayListener onClickAway={() => setShowWalletAddressConfirm(false)}>
            <Tooltip
              onClose={() => setShowWalletAddressConfirm(false)}
              open={showWalletAddressConfirm}
              disableFocusListener
              disableHoverListener
              disableTouchListener
              placement="top"
              title="Address Copied"
            >
              <IconButton
                sx={{ m: 0, p: 0 }}
                onClick={() => copyWalletAddress(selectedWallet.address as string)}
                disableRipple
                disableFocusRipple
              >
                <SvgIcon component={Icons.CopyIcon} inheritViewBox sx={copyIconStyle} />
              </IconButton>
            </Tooltip>
          </ClickAwayListener>

          <IconButton sx={{ m: 0, p: 0 }} href={scanWalletAddress} target="_blank" disableRipple={true}>
            <SvgIcon component={Icons.GoToIcon} inheritViewBox sx={goToIconStyle} />
          </IconButton>
        </Grid>
        <Typography variant="subtitle2" sx={{ textAlign: "right" }}>
          #{selectedWallet.walletId}
        </Typography>
      </Grid>
    );
  }

  function deribitField() {
    return (
      <Grid>
        <Typography variant="subtitle1">
          <SvgIcon component={iconsMap["DBIT"]} inheritViewBox sx={{ width: 20, height: 20, mr: 1, mb: -0.6 }} />
          {collateralRequest.exchange}
        </Typography>
      </Grid>
    );
  }

  return (
    <>
      {isLoading && <Skeleton variant="rectangular" height={500} width={520} />}
      {!isLoading && (
        <Grid container spacing={1}>
          <Grid xs={12}>
            <Typography sx={{ fontWeight: 600 }}>Source</Typography>
            <Grid>
              <Grid container justifyContent={"space-between"} sx={{ mt: 1 }}>
                <Grid>
                  <Typography variant="subtitle2">CLIENT</Typography>
                </Grid>
                <Grid>
                  <Typography variant="subtitle1">{collateralRequest.clientName || account?.name}</Typography>
                </Grid>
              </Grid>
              <Grid container justifyContent={"space-between"} sx={{ mt: 1 }}>
                <Grid>
                  <Typography variant="subtitle2">ORGANIZATION</Typography>
                </Grid>
                <Grid>
                  <Typography variant="subtitle1">{collateralRequest.orgName || selectedOrg.name}</Typography>
                </Grid>
              </Grid>
              <Grid container justifyContent={"space-between"} sx={{ mt: 1 }}>
                <Grid>
                  <Typography variant="subtitle2">REQUESTED BY</Typography>
                </Grid>
                <Grid>
                  <Typography variant="subtitle1">
                    {collateralRequest.requesterName || userInfo.preferred_username}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container justifyContent={"space-between"} sx={{ mt: 1 }}>
                <Grid>
                  <Typography variant="subtitle2">ACTION</Typography>
                </Grid>
                <Grid>
                  <Typography variant="subtitle1">{collateralRequest.action?.replaceAll("_", " ")}</Typography>
                </Grid>
              </Grid>
              <Grid container justifyContent={"space-between"} sx={{ mt: 1 }}>
                <Grid>
                  <Typography variant="subtitle2">ORIGINATION</Typography>
                </Grid>
                {collateralRequest.action === WalletTransactionTypeEnum.AddCollateralWithdraw
                  ? segregatedWalletField()
                  : deribitField()}
              </Grid>
            </Grid>
          </Grid>
          <Grid xs={12}>
            <Typography sx={{ fontWeight: 600 }}>Destination</Typography>
            <Grid>
              <Grid container justifyContent={"space-between"} sx={{ mt: 1 }}>
                <Grid>
                  <Typography variant="subtitle2">
                    {collateralRequest.action === WalletTransactionTypeEnum.AddCollateralWithdraw
                      ? "EXCHANGE"
                      : "INTERNAL"}
                  </Typography>
                </Grid>
                {collateralRequest.action === WalletTransactionTypeEnum.AddCollateralWithdraw
                  ? deribitField()
                  : segregatedWalletField()}
              </Grid>
            </Grid>
          </Grid>
          <Grid xs={12}>
            <Typography sx={{ fontWeight: 600 }}>Amounts</Typography>
            <Grid container justifyContent={"space-between"} sx={{ mt: 1 }}>
              <Grid>
                <Typography variant="subtitle2">AVAILABLE BALANCE</Typography>
              </Grid>
              <Grid direction="row" sx={{ textAlign: "right" }}>
                <Typography variant="subtitle1">
                  <QuantityDisplay quantity={availableBalance || 0} ticker={selectedWallet.assetTicker || ""} />{" "}
                  {(selectedWallet && selectedWallet.assetTicker) || ""}
                </Typography>
                <Typography variant="subtitle1">
                  {formatDollarAmountUsd(
                    getPriceByAssetQty(
                      collateralRequest.assetTicker as string,
                      Number(availableBalance),
                      priceFeed,
                      assets,
                    ) || 0,
                  )}{" "}
                  USD
                </Typography>
              </Grid>
            </Grid>
            <Grid container justifyContent={"space-between"} sx={{ mt: 1 }}>
              <Grid>
                <Typography variant="subtitle2">COLLATERAL BALANCE</Typography>
              </Grid>
              <Grid direction="row" sx={{ textAlign: "right" }}>
                <Typography variant="subtitle1">
                  {formatCryptoQuantity(
                    Number(collateralBalance) || 0,
                    collateralRequest.assetTicker || "",
                    "long",
                    getDecimalLength(Number(collateralBalance)),
                  )}{" "}
                  {(selectedWallet && selectedWallet.assetTicker) || ""}
                </Typography>
                <Typography variant="subtitle1">
                  {formatDollarAmountUsd(
                    getPriceByAssetQty(
                      collateralRequest.assetTicker as string,
                      Number(collateralBalance),
                      priceFeed,
                      assets,
                    ) || 0,
                  )}{" "}
                  USD
                </Typography>
              </Grid>
            </Grid>
            <Grid container justifyContent={"space-between"} sx={{ mt: 1 }}>
              <Grid>
                <Typography variant="subtitle2">COLLATERAL AMOUNT</Typography>
              </Grid>
              <Grid direction="row" sx={{ textAlign: "right" }}>
                <Typography variant="subtitle1">
                  {formatCryptoQuantity(
                    Number(collateralRequest.quantity) || 0,
                    collateralRequest.assetTicker || "",
                    "long",
                    getDecimalLength(Number(collateralRequest.quantity)),
                  )}{" "}
                  {(selectedWallet && selectedWallet.assetTicker) || ""}
                </Typography>
                <Typography variant="subtitle1">
                  {formatDollarAmountUsd(
                    getPriceByAssetQty(
                      collateralRequest.assetTicker as string,
                      Number(collateralRequest.quantity),
                      priceFeed,
                      assets,
                    ) || 0,
                  )}{" "}
                  USD
                </Typography>
              </Grid>
            </Grid>
            <Grid container justifyContent={"space-between"} sx={{ mt: 1 }}>
              <Grid>
                <Typography variant="subtitle2">NETWORK FEE ESTIMATION</Typography>
              </Grid>
              <Grid direction="row" sx={{ textAlign: "right" }}>
                <Typography variant="subtitle1">
                  {formatCryptoQuantity(
                    Number(networkFeeEstimation?.networkFee) || 0,
                    selectedWallet.assetTicker || "",
                    "long",
                  )}{" "}
                  {isERC20 ? CryptoTickerEnum.ETH : (selectedWallet && selectedWallet.assetTicker) || ""}
                </Typography>
                <Typography variant="subtitle1">
                  {formatDollarAmountUsd(
                    getPriceByAssetQty(networkFeeTicker, Number(networkFeeEstimation?.networkFee), priceFeed, assets) ||
                      0,
                  )}{" "}
                  USD
                </Typography>
                {isERC20 && feeWallet && (
                  <Stack direction={"row"} sx={{ p: 0, m: 0 }} justifyContent={"flex-end"} alignContent={"center"}>
                    <Typography variant="caption" sx={{ fontStyle: "italic" }}>
                      *Fee amount will be deducted in ETH
                    </Typography>
                    <Tooltip
                      title={
                        <Typography variant="body2">
                          Fee amount will be deducted in ETH from {feeWallet.name} for ERC20 transactions
                        </Typography>
                      }
                      placement="top-end"
                    >
                      <Box>
                        <SvgIcon component={Icons.InformationIcon} sx={{ width: 20, height: 20 }} />
                      </Box>
                    </Tooltip>
                  </Stack>
                )}
              </Grid>
            </Grid>
          </Grid>
          <Grid xs={12}>
            <Grid sx={{ borderBottom: 1, borderColor: theme.palette.divider, pb: 2 }}></Grid>
            <Grid container justifyContent={"space-between"} sx={{ mt: 1 }}>
              <Grid>
                <Typography variant="subtitle2">UPDATED COLLATERAL BALANCE</Typography>
              </Grid>
              <Grid direction="row" sx={{ textAlign: "right" }}>
                <Typography variant="subtitle1">
                  <QuantityDisplay quantity={updatedCollateralBalance || 0} ticker={selectedWallet.assetTicker || ""} />{" "}
                  {(selectedWallet && selectedWallet.assetTicker) || ""}
                </Typography>
                <Typography variant="subtitle1">
                  {formatDollarAmountUsd(
                    getPriceByAssetQty(
                      collateralRequest.assetTicker as string,
                      Number(updatedCollateralBalance),
                      priceFeed,
                      assets,
                    ) || 0,
                  )}{" "}
                  USD
                </Typography>
              </Grid>
            </Grid>
            <Grid container justifyContent={"space-between"} sx={{ mt: 1 }}>
              <Grid>
                <Typography variant="subtitle2">UPDATED AVAILABLE BALANCE</Typography>
              </Grid>
              <Grid direction="row" sx={{ textAlign: "right" }}>
                <Typography variant="subtitle1">
                  <QuantityDisplay quantity={updatedBalance || 0} ticker={selectedWallet.assetTicker || ""} />{" "}
                  {(selectedWallet && selectedWallet.assetTicker) || ""}
                </Typography>
                <Typography variant="subtitle1">
                  {formatDollarAmountUsd(
                    getPriceByAssetQty(
                      collateralRequest.assetTicker as string,
                      Number(updatedBalance),
                      priceFeed,
                      assets,
                    ) || 0,
                  )}{" "}
                  USD
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default ReviewCollateralDetails;
