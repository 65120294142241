import { Button, DialogTitle } from "@bakkt/bakkt-ui-components";
import { Dialog, DialogContent, DialogActions } from "@mui/material";
import { useState } from "react";
import { LoaderFunctionArgs, defer, useLoaderData, useLocation, useNavigate } from "react-router-dom";
import { fetchMockDataPromiseWithDelay, walletTransactions } from "../../../services/mockData";
import { PolicyActionActionEnum, WalletTransaction, WalletTransactionTypeEnum } from "../../../services/openAPI/client";
import { WalletTransactionService } from "../../../services/serviceLoader";
import { shouldUseMockData } from "../../../utils/dataUtils";
import { isApprover, isPolicyDisabled } from "../../../utils/permissionsUtil";
import ReviewCollateralDetails from "./ReviewCollateralDetails";
import { useRootContext, RootContextType } from "../../../RootLayout";
import { CollateralMinimal } from "./CollateralContext";

const ReviewCollateral = () => {
  const { selectedOrg, userInfo } = useRootContext() as RootContextType;
  const navigate = useNavigate();
  const { state } = useLocation();
  const isApproved = state.approved;
  const policyItem = isApproved ? state.policyItem : state.data;
  const [open, setOpen] = useState(true);

  const { walletTransaction } = useLoaderData() as {
    walletTransaction: WalletTransaction;
  };

  const offExchangeWalletId =
    walletTransaction.type === WalletTransactionTypeEnum.AddCollateralWithdraw
      ? walletTransaction.destinationId
      : walletTransaction.sourceId;

  const collateralFormData: Partial<CollateralMinimal> = {
    assetTicker: walletTransaction.assetTicker,
    fromWalletId: walletTransaction.sourceId,
    fromWalletAddress: walletTransaction.sourceName,
    toWalletAddress: walletTransaction.destinationAddress,
    toWalletId: walletTransaction.destinationId,
    offExchangeWalletId: offExchangeWalletId,
    exchange: "DERIBIT",
    action: walletTransaction.type,
    quantity: walletTransaction.quantity as number,
    createdOn: policyItem.timestamp,
    clientName: policyItem.client,
    orgName: policyItem.organization,
    requesterName: policyItem.requester,
  };

  const thirdActionSx = { selfAlign: "start", marginRight: "auto" };

  const handleClose = () => {
    setOpen(false);
    navigate(-1);
  };

  const handleDecline = () => {
    navigate(
      `/${selectedOrg.id}/${PolicyActionActionEnum.Deny.toLowerCase()}-collateral-request/${policyItem.policyActionId}`,
      {
        state: policyItem,
      },
    );
  };

  const handleCancel = () => {
    setOpen(false);
    navigate(-1);
  };

  const handleConfirm = () => {
    navigate(
      `/${selectedOrg.id}/${PolicyActionActionEnum.Approve.toLowerCase()}-collateral-request/${
        policyItem.policyActionId
      }`,
      { state: policyItem },
    );
  };

  const isDisabled = isPolicyDisabled(
    policyItem.approvalCount,
    policyItem?.consensusNumber,
    policyItem.policyInstanceStatus,
    policyItem.policySequenceStatus,
    isApproved,
  );

  return (
    <>
      <Dialog open={open} onClose={handleClose} maxWidth={"sm"} fullWidth={false}>
        <DialogTitle title="Review Collateral Request" severity="warning">
          Review your collateral change details below.
        </DialogTitle>
        <DialogContent>
          <ReviewCollateralDetails collateralRequest={collateralFormData} />
        </DialogContent>
        <DialogActions>
          <Button variant={"outlined"} onClick={handleCancel} sx={thirdActionSx}>
            Go Back
          </Button>

          {isApprover(userInfo, Number(selectedOrg.id)) && (
            <>
              <Button variant={"outlined"} onClick={handleDecline} disabled={isDisabled} sx={{ mr: 1 }}>
                Decline
              </Button>

              <Button variant={"contained"} onClick={handleConfirm} disabled={isDisabled} autoFocus>
                Approve
              </Button>
            </>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
};

export async function loader({ params }: LoaderFunctionArgs) {
  const walletTransactionId = Number(params.walletTransactionId);
  const orgId = Number(params.organizationId);

  const transactionRequestItem = shouldUseMockData
    ? ((await fetchMockDataPromiseWithDelay(walletTransactions[8], 300)) as WalletTransaction)
    : await (WalletTransactionService.getWalletTransactionById(walletTransactionId) as WalletTransaction);

  const requestedWalletTransactions = shouldUseMockData
    ? fetchMockDataPromiseWithDelay(walletTransactions, 500)
    : WalletTransactionService.getWalletTransactions(transactionRequestItem.sourceId, orgId);

  const allWalletTransactionsForOrg = shouldUseMockData
    ? fetchMockDataPromiseWithDelay(walletTransactions, 500)
    : WalletTransactionService.getWalletTransactions(undefined, orgId);

  return defer({
    walletTransaction: transactionRequestItem,
    walletTransactions: await requestedWalletTransactions,
    orgWalletTransactions: await allWalletTransactionsForOrg,
  });
}

export default ReviewCollateral;
