import { Typography, formatDollarAmountUsd } from "@bakkt/bakkt-ui-components";
import { Container, Unstable_Grid2 as Grid } from "@mui/material";
import { innerGridContainer, tableHeader, warmColdValues } from "../styles.tsx";
import { dashboardBalanceLabel } from "../../../utils/customTypes.ts";

interface WalletBalanceContainerProps {
  isREL009PublicFeature?: boolean;
  balanceData: number;
  label: string;
}

function WalletBalanceContainer({ isREL009PublicFeature, balanceData, label }: WalletBalanceContainerProps) {
  const displayLabel = (label: string) => {
    const displayTempReturn = isREL009PublicFeature ? `Segregated ${label}` : label;
    switch (label) {
      case dashboardBalanceLabel.cold:
        return displayTempReturn;
      case dashboardBalanceLabel.warm:
        return displayTempReturn;
      case dashboardBalanceLabel.segregated:
        return isREL009PublicFeature ? label : "WALLETS";
      default:
        return label;
    }
  };

  const containerSize = (label: string) => {
    switch (label) {
      case dashboardBalanceLabel.cold:
        return 2.5;
      case dashboardBalanceLabel.warm:
        return 2.5;
      case dashboardBalanceLabel.segregated:
        return 3;
      case dashboardBalanceLabel.offExchange:
        return 2.5;
      default:
        return 1;
    }
  };

  return (
    <Grid container xs={containerSize(label)}>
      <Container data-testid={`walletBalanceContainer-${label}`} sx={innerGridContainer}>
        <Typography variant="overline" data-testid="label" sx={tableHeader}>
          {displayLabel(label)}
        </Typography>
        <Typography variant="h5" data-testid="amount" sx={warmColdValues}>
          {/* Update to display total custodial warm value */}
          {formatDollarAmountUsd(balanceData || 0)}
        </Typography>

        {/*TODO: Coming back in Phase 2*/}
        {/*{balanceLabel === "Total Balance" && (*/}
        {/*  <div data-testid={"percentChange"}>*/}
        {/*    <UpDownPill direction={"down"} text={"3.2%"} />*/}
        {/*    <span>Last 24 hours</span>*/}
        {/*  </div>*/}
        {/*)}*/}
      </Container>
    </Grid>
  );
}

export default WalletBalanceContainer;
