import { createContext } from "react";
import { SelectChangeEvent } from "@mui/material";
import { Wallet } from "../../../services/openAPI/client";

export interface CollateralMinimal {
  assetTicker: string;
  fromWalletId: number | null;
  fromWalletAddress: string | null;
  toWalletId: number | null;
  toWalletAddress: string | null;
  offExchangeWalletId: number | null;
  exchange: string;
  action: string;
  quantity: string | number | null;
  originatorId: number;
  createdOn: number;
  accountId: number;
  organizationId: number;
  clientName: string;
  orgName: string;
  requesterName: string;
}

export interface ManageCollateralContext {
  selectedAsset: string;
  currencyView: string;
  collateral: Partial<CollateralMinimal>;
  collateralAction: string;
  collateralStep: number;
  selectedWallet: Wallet | null;
  selectedWalletId: number | null;
  offExchangeWallet: Wallet | null;
  handleInputChange: (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement> | SelectChangeEvent) => void;
  handleCurrencyToggle: (event: React.MouseEvent<HTMLElement>, currency: string | null) => void;
  handleCollateralAction: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleChangeWallet: (event: SelectChangeEvent) => void;
  handleChangeAsset: (event: SelectChangeEvent) => void;
  updateField: (fieldName: string, value: string | number | boolean) => void;
  handleReviewCollateralRequest: () => void;
  handleCreateCollateralRequest: () => void;
}

const defaultCollateralContextValue: ManageCollateralContext = {
  selectedAsset: "",
  currencyView: "",
  collateral: {},
  collateralAction: "",
  selectedWallet: null,
  selectedWalletId: null,
  offExchangeWallet: null,
  collateralStep: 1,
  handleInputChange: () => {
    undefined;
  },
  handleCurrencyToggle: () => {
    undefined;
  },
  handleCollateralAction: () => {
    undefined;
  },
  handleChangeWallet: () => {
    undefined;
  },
  handleChangeAsset: () => {
    undefined;
  },
  updateField: () => {
    undefined;
  },
  handleReviewCollateralRequest: () => {
    undefined;
  },
  handleCreateCollateralRequest: () => {
    undefined;
  },
};

export const ManageCollateralContext = createContext<Partial<ManageCollateralContext>>(defaultCollateralContextValue);
