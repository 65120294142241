import { RootContextType, useRootContext } from "../../../RootLayout";
import { useNavigate } from "react-router-dom";
import { useContext, useState } from "react";
import { Button, DialogTitle, Divider } from "@bakkt/bakkt-ui-components";
import { Dialog, DialogActions, DialogContent, Container } from "@mui/material";
import { getPriceByAssetQty, isTrustWallet, MAX_WITHDRAW_LIMIT } from "../../../utils/dataUtils";
import ReviewWithdrawDetails from "../ReviewWithdrawDetails";

import { MIN_GAS_BALANCE } from "../../../utils/constants";
import ERC20Banner from "../../../components/banners/ERC20Banner.tsx";
import SelectWithdrawSource from "./WithdrawSourceWallet.tsx";
import { withdrawContext } from "./WithdrawContext.tsx";
import WithdrawDestinationWallet from "./WithdrawDestinationWallet.tsx";

function WithdrawModal() {
  const withdrawCtx = useContext(withdrawContext);
  const {
    withdraw,
    withdrawStep,
    selectedAsset,
    currencyView,
    selectedWallet,
    allowListedAddresses,
    gasBalance,
    isERC20,
    feeWallet,
    destinationAddressType,
    balances,
    handleCreateWithdraw,
    handleReviewWithdraw,
  } = withdrawCtx;

  const { priceFeed, assets } = useRootContext() as RootContextType;

  const navigate = useNavigate();

  const [enableCreateWithdraw, setEnableCreateWithdraw] = useState<boolean>(false);

  const [open, setOpen] = useState(true);
  const handleClose = () => {
    setOpen(false);
    navigate(-1);
  };

  const getWithdrawValue = () => {
    let amount = Number(withdraw.quantity);
    if (currencyView === "CRYPTO") {
      amount = getPriceByAssetQty(selectedAsset, amount, priceFeed, assets);
    }
    return amount;
  };

  const isWithdrawFormValid = () => {
    const isValid = (val: any) => {
      if (val) return true;
    };
    const isPositive = (val: any) => {
      if (Number(val) > 0) return true;
    };
    const isUnderMax = (val: any) => {
      if (Number(val) < MAX_WITHDRAW_LIMIT) return true;
    };
    const isWithinAvailableBalance = (val: any) => {
      if (currencyView === "CRYPTO" && Number(val) <= balances.availableBalanceCrypto) {
        return true;
      } else if (currencyView !== "CRYPTO" && Number(val) <= balances.availableBalanceUsd) {
        return true;
      }
    };

    if (
      isValid(withdraw?.toWalletAddress) &&
      isValid(withdraw?.fromWalletAddress) &&
      isValid(withdraw?.fromWalletId) &&
      isValid(destinationAddressType) &&
      isValid(withdraw?.quantity) &&
      isPositive(withdraw?.quantity) &&
      isWithinAvailableBalance(withdraw?.quantity) &&
      isValid(withdraw?.assetTicker) &&
      isValid(withdraw?.allowListId) &&
      isValid(withdraw?.allowListName) &&
      isUnderMax(getWithdrawValue())
    )
      return true;
  };

  const isEnoughGasBalance = () => {
    if (isTrustWallet(selectedWallet) && isERC20 && gasBalance < MIN_GAS_BALANCE) {
      return false;
    }
    return true;
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth={"md"} fullWidth={false}>
      {withdrawStep == 1 && (
        <>
          <DialogTitle title="Withdraw" severity="secondary">
            Specify your withdrawal details below. Withdrawals can only be sent to allowlist addresses. New addresses
            may require more than 48 hours for approval before they can be used.
          </DialogTitle>
          <DialogContent>
            {isTrustWallet(selectedWallet) && isERC20 && gasBalance < MIN_GAS_BALANCE && (
              <ERC20Banner gasBalance={gasBalance} feeWallet={feeWallet} />
            )}
            <Container>
              <SelectWithdrawSource />
              <Divider sx={{ my: 5 }} />
              <WithdrawDestinationWallet />
            </Container>
          </DialogContent>
          <DialogActions>
            <Button variant={"contained"} onClick={handleClose} autoFocus>
              Close
            </Button>
            <Button
              variant={"contained"}
              onClick={handleReviewWithdraw}
              disabled={!(isWithdrawFormValid() && isEnoughGasBalance())}
              autoFocus
            >
              Review Withdraw
            </Button>
          </DialogActions>
        </>
      )}
      {withdrawStep == 2 && (
        <>
          <DialogTitle title="Review Withdraw" severity="warning">
            Review your withdrawal details below.
          </DialogTitle>
          <DialogContent>
            <ReviewWithdrawDetails
              withdrawRequest={withdraw}
              allowListedAddresses={allowListedAddresses}
              setEnableWithdraw={setEnableCreateWithdraw}
            />
          </DialogContent>
          <DialogActions>
            <Button variant={"contained"} onClick={handleClose} autoFocus>
              Close
            </Button>
            <Button variant={"contained"} autoFocus onClick={handleCreateWithdraw} disabled={!enableCreateWithdraw}>
              Create Withdraw Request
            </Button>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
}

export default WithdrawModal;
