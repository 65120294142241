import { Typography, Divider, formatWalletAddress, Icons, color, TextField } from "@bakkt/bakkt-ui-components";
import {
  ClickAwayListener,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  SvgIcon,
  Tooltip,
  Unstable_Grid2 as Grid,
  Box,
  Chip,
  ToggleButtonGroup,
  ToggleButton,
  InputAdornment,
  useTheme,
} from "@mui/material";
import { WalletBalanceAvailableOnly } from "../balances/WalletBalanceAvailableOnly";
import { AssetSVGIcon } from "../../../components/customSVG/AssetSVGIcon";
import { checkWarmBalance, checkWarmWallet, getScanLink } from "../../../utils/dataUtils";
import { CryptoTickerEnum } from "../../../utils/CryptoIconsMap";
import { RootContextType, useRootContext } from "../../../RootLayout";
import { PriceInfo, Wallet } from "../../../services/openAPI/client";
import { WalletBalanceAvailableMinimal } from "../balances/WalletBalanceAvailableMinimal";
import { useContext, useState } from "react";
import { ManageCollateralContext } from "./CollateralContext";
import { collateralActionType } from "../../../utils/customTypes";

const SelectCollateralSource = () => {
  const context = useContext(ManageCollateralContext);
  const {
    collateral,
    selectedWallet,
    selectedWalletId,
    selectedAsset,
    handleChangeWallet,
    handleChangeAsset,
    handleCurrencyToggle,
    handleInputChange,
    currencyView,
    collateralAction,
  } = context;
  const { orgDataCache, priceFeed, assets, networks } = useRootContext() as RootContextType;
  const wallets = [...orgDataCache.wallets];

  const isWarmBalance = checkWarmBalance(wallets as Wallet[], priceFeed as PriceInfo[], assets);

  const [showCopyAddressConfirm, setShowCopyAddressConfirm] = useState(false);

  const handleTooltipClose = () => {
    setShowCopyAddressConfirm(false);
  };

  const copyAddress = () => {
    navigator.clipboard.writeText(selectedWallet?.address as string);
    setShowCopyAddressConfirm(true);
  };

  const bodyHeadingSx = {
    color: color.text.secondary,
    fontWeight: "bold",
  };

  const theme = useTheme();
  const buttonToggleStyle = {
    "&.Mui-selected, &.Mui-selected:hover": {
      color: "white",
      backgroundColor: theme.palette.secondary.main,
    },
  };

  const scanWalletAddress = getScanLink(
    selectedWallet?.assetTicker || "",
    selectedWallet?.address || "",
    assets,
    networks,
  );

  return (
    <>
      <Typography variant="h5" sx={{ mb: 1 }}>
        {collateralAction === collateralActionType.add ? "Source" : "Destination"} Wallet
      </Typography>
      <Grid container direction={"row"} columnGap={6} sx={{ mb: 5 }}>
        <Grid xs={6}>
          <>
            <FormControl variant="standard" sx={{ width: "100%", mb: 3 }} required>
              <InputLabel id="select-asset">Asset</InputLabel>
              <Select
                name="assetTicker"
                value={selectedAsset ? (selectedAsset as any) : ""}
                label="asset"
                onChange={handleChangeAsset}
                disabled
              >
                {assets.map((asset, index) => (
                  <MenuItem key={index} value={asset.ticker} disabled={asset.ticker === CryptoTickerEnum.ETC}>
                    {asset.ticker === CryptoTickerEnum.ETC ? (
                      <>
                        <Grid container alignItems={"center"} sx={{ p: 1, pb: 0 }}>
                          <SvgIcon
                            component={() =>
                              AssetSVGIcon({
                                svgString: asset.iconSVG,
                                title: asset.ticker,
                                sx: { width: 32, height: 32, pr: 1 },
                              })
                            }
                            inheritViewBox
                          />
                          <Typography sx={{ fontWeight: 600, mr: 1 }}>{asset.name}</Typography>
                          <Typography variant={"body2"} sx={{ mr: 1 }}>
                            {asset.ticker}
                          </Typography>
                          <Chip key={asset.ticker} label={"Contact Support"} />
                        </Grid>
                      </>
                    ) : (
                      <>
                        <Grid container alignItems={"center"} sx={{ p: 1, pb: 0 }}>
                          <SvgIcon
                            component={() =>
                              AssetSVGIcon({
                                svgString: asset.iconSVG,
                                title: asset.ticker,
                                sx: { width: 32, height: 32, pr: 1 },
                              })
                            }
                            inheritViewBox
                          />
                          <Typography sx={{ fontWeight: 600, mr: 1 }}>{asset.name}</Typography>
                          <Typography variant={"body2"}>{asset.ticker}</Typography>
                        </Grid>
                      </>
                    )}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl variant="standard" sx={{ width: "100%" }} required>
              <InputLabel id="select-wallet">Wallet</InputLabel>
              <Select
                name="fromWalletId"
                value={selectedWalletId ? (selectedWalletId as any) : ""}
                renderValue={() => `${selectedWallet?.name}`}
                label="Wallet"
                onChange={handleChangeWallet}
                MenuProps={{
                  sx: { maxHeight: 300 },
                }}
              >
                {wallets
                  .filter(
                    (wallet) =>
                      wallet.assetTicker === selectedAsset &&
                      checkWarmWallet(Boolean(isWarmBalance), wallet.temperature as string),
                  )
                  .map((wallet) => (
                    <MenuItem key={wallet.walletId} value={wallet.walletId}>
                      <Box>
                        <Typography variant="h5">{wallet.name}</Typography>
                        <Typography variant={"body1"}>{`ID: ${wallet.walletId}`}</Typography>
                      </Box>
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </>
        </Grid>

        <Divider orientation="vertical" flexItem />
        <Grid container direction={"column"}>
          {selectedWallet ? (
            <>
              <Box sx={{ mb: 3 }}>
                <Typography variant="body2" sx={bodyHeadingSx}>
                  {selectedWallet.name} ADDRESS
                </Typography>
                {formatWalletAddress(selectedWallet.address as string)}
                <ClickAwayListener onClickAway={handleTooltipClose}>
                  <Tooltip
                    onClose={handleTooltipClose}
                    open={showCopyAddressConfirm}
                    disableFocusListener
                    disableHoverListener
                    disableTouchListener
                    placement="top"
                    title="Address Copied"
                  >
                    <IconButton sx={{ m: 0, p: 0 }} onClick={copyAddress} disableRipple disableFocusRipple>
                      <SvgIcon
                        component={Icons.CopyIcon}
                        inheritViewBox
                        sx={{ width: "15px", height: "15px", ml: 1, mr: 1.2 }}
                      />
                    </IconButton>
                  </Tooltip>
                </ClickAwayListener>
                <IconButton sx={{ m: 0, p: 0 }} disableRipple={true} href={scanWalletAddress} target="_blank">
                  <SvgIcon component={Icons.GoToIcon} inheritViewBox sx={{ width: "16px", height: "16px" }} />
                </IconButton>
              </Box>

              {selectedWalletId && <WalletBalanceAvailableOnly walletId={selectedWalletId.toString() as string} />}
            </>
          ) : (
            <>
              <Typography variant="body2">AMOUNT AVAILABLE</Typography>
              <Typography variant="h5">Wallet Not Selected</Typography>
              <Typography variant="subtitle1">$0.00</Typography>
            </>
          )}
        </Grid>
      </Grid>

      <Grid container xs={12} alignItems={"flex-end"} justifyContent={"space-between"} sx={{ mb: 2 }}>
        <Grid xs={10}>
          <FormControl fullWidth variant="standard" required>
            <TextField
              name="quantity"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    {currencyView === "CRYPTO" ? selectedAsset : "USD $"}
                  </InputAdornment>
                ),
              }}
              value={collateral?.quantity}
              onChange={handleInputChange}
              label="Amount"
              variant="standard"
              required
            />
          </FormControl>
        </Grid>

        <FormControl>
          <ToggleButtonGroup value={currencyView} exclusive onChange={handleCurrencyToggle} aria-label="currency-view">
            <ToggleButton value="CRYPTO" aria-label="left aligned" sx={buttonToggleStyle}>
              <SvgIcon component={Icons.CryptoIcon} inheritViewBox sx={{ width: "18px", height: "18px" }} />
            </ToggleButton>
            <ToggleButton value="USD" aria-label="centered" sx={buttonToggleStyle}>
              <SvgIcon component={Icons.CashIcon} inheritViewBox sx={{ width: "18px", height: "18px" }} />
            </ToggleButton>
          </ToggleButtonGroup>
        </FormControl>
      </Grid>
      <Grid>
        <WalletBalanceAvailableMinimal
          ticker={selectedAsset as string}
          amount={collateral?.quantity as string}
          view={currencyView as string}
        />
      </Grid>
    </>
  );
};

export default SelectCollateralSource;
