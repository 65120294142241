import { Container, Skeleton } from "@mui/material";
import { innerGridContainer, wrapperContainer } from "./styles.tsx";
import { Alert, Paper, Typography } from "@bakkt/bakkt-ui-components";
import { Unstable_Grid2 as Grid } from "@mui/material";
import React from "react";
import { Await } from "react-router-dom";
import { RootContextType, useRootContext } from "../../RootLayout";
import OffExchangeAggregates from "./OffExchangeAggregates.tsx";
import { OffExchangeWalletsGrid } from "./OffExchangeWalletsGrid.tsx";
import { calculateOffExchangeTotalsFromWallets } from "../../utils/dataUtils.ts";

export default function OffExchangeWallets() {
  const { priceFeed, assets, orgDataCache } = useRootContext() as RootContextType;

  const selectedOrgOffExchangeWallets = orgDataCache.offExchangeWallets;

  const balances = calculateOffExchangeTotalsFromWallets(selectedOrgOffExchangeWallets, priceFeed, assets);
  return (
    <Container maxWidth="xl" disableGutters sx={wrapperContainer}>
      <Grid container xs={12} justifyContent={"space-between"} spacing={2} sx={{ mb: 4 }}>
        <Typography variant="h3" data-testid="off-exchange-wallets">
          Off Exchange
        </Typography>
      </Grid>

      <Paper>
        <Grid container spacing={2}>
          <Grid xs={12} sx={{ p: 2 }}>
            <Container sx={innerGridContainer} data-testid="off-exchange-wallets-table">
              {
                <React.Suspense fallback={<Skeleton variant="rectangular" height={110} />}>
                  <Await
                    resolve={selectedOrgOffExchangeWallets}
                    errorElement={<Alert severity="error">Error loading wallet data!</Alert>}
                  >
                    <OffExchangeAggregates allBalances={balances} />
                    <OffExchangeWalletsGrid />
                  </Await>
                </React.Suspense>
              }
            </Container>
          </Grid>
        </Grid>
      </Paper>
    </Container>
  );
}
