import { Box } from "@mui/material";
import { PolicyActionDetails, PolicyInstanceRequestTypeEnum, PolicyStatusEnum } from "../../services/openAPI/client";
import { useLocation, Link as RouterLink } from "react-router-dom";
import { getRouteByRequestCategory } from "../../utils/dataUtils";
import { color } from "@bakkt/bakkt-ui-components";

interface ConsensusLinkProps {
  policyItem: PolicyActionDetails;
  linkStyle?: object;
  linkState?: object;
}

const ConsensusLink = ({ policyItem, linkStyle, linkState }: ConsensusLinkProps) => {
  const location = useLocation();
  const pendingStatusDisplay = (policy: PolicyActionDetails) => {
    if (
      policy.policySequenceStatus === PolicyStatusEnum.Cancelled ||
      policy.policyInstanceStatus === PolicyStatusEnum.Cancelled
    ) {
      return PolicyStatusEnum.Cancelled;
    } else if (Number(policy.approvalCount) < 1 && Number(policy.approvalCount) < Number(policy.consensusNumber)) {
      return policy.policySequenceStatus;
    } else if (
      policy.policySequenceStatus === PolicyStatusEnum.Completed &&
      policy.policyInstanceStatus === PolicyStatusEnum.Completed
    ) {
      return PolicyStatusEnum.Completed;
    } else if (
      policyItem.approvalCount === policyItem.consensusNumber &&
      policyItem.policySequenceStatus === PolicyStatusEnum.Completed &&
      policyItem.policyInstanceStatus === PolicyStatusEnum.Initiated
    ) {
      return "BAKKT REVIEW";
    } else if (policy.policyInstanceRequestType === PolicyInstanceRequestTypeEnum.Settlement) {
      return PolicyStatusEnum.Acknowledgement;
    } else {
      return "PENDING APPROVAL";
    }
  };

  return (
    <RouterLink
      to={`${location.pathname}/review-${getRouteByRequestCategory(policyItem)}/${
        JSON.parse(String(policyItem.policyInstancePayload)).entityId
      }`}
      style={{
        ...linkStyle,
        color:
          pendingStatusDisplay(policyItem) === "PENDING APPROVAL" ? color.engagingOrange.dark : color.techBlack[700],
      }}
      state={linkState}
    >
      <Box component="span" sx={{ textDecoration: "underline" }}>
        {pendingStatusDisplay(policyItem)}
      </Box>
    </RouterLink>
  );
};

export default ConsensusLink;
