import { Wallet } from "../../services/openAPI/client";
import { Alert, Typography } from "@bakkt/bakkt-ui-components";
import { MIN_GAS_BALANCE } from "../../utils/constants";

interface ERC20BannerProps {
  gasBalance: number;
  feeWallet: Wallet | null;
}

function ERC20Banner({ gasBalance, feeWallet }: ERC20BannerProps) {
  return (
    <Alert severity="warning" sx={{ mb: 2 }}>
      <Typography variant={"subtitle2"}>
        This ERC-20 asset’s fee wallet has insufficient funds of {gasBalance} ETH.
        <Typography variant={"subtitle2"}>
          ERC-20 assets require a minimum balance of {MIN_GAS_BALANCE} ETH in {feeWallet?.name} wallet for GAS fees.
        </Typography>
        <Typography variant={"subtitle2"}>
          *Please fund this fee wallet {feeWallet?.address} with at least {MIN_GAS_BALANCE} ETH in order to proceed
        </Typography>
      </Typography>
    </Alert>
  );
}

export default ERC20Banner;
