import { useState, useEffect } from "react";
import { AllowlistAddress, EstimateFee, Wallet } from "../services/openAPI/client";
import { WalletTransactionService } from "../services/serviceLoader";
import { CollateralMinimal } from "../pages/wallet/manageCollateral/CollateralContext";

import { CryptoTickerEnum } from "../utils/CryptoIconsMap";
import { isERC20Coin } from "../utils/dataUtils";
import { WithdrawMinimal } from "../pages/wallet/withdraw/WithdrawContext";

const useGetNetworkFeeEstimation = (
  transactionRequest: Partial<WithdrawMinimal> | Partial<CollateralMinimal>,
  selectedWallet: Wallet,
  selectedDestinationAddress: AllowlistAddress | Wallet | undefined,
  wallets: Wallet[],
) => {
  const [networkFeeEstimation, setNetworkFeeEstimation] = useState<EstimateFee>({});
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const isERC20 = isERC20Coin(selectedWallet?.assetTicker as string);

  const networkFeeTicker = isERC20 ? CryptoTickerEnum.ETH : (transactionRequest.assetTicker as string);
  const feeWallet = isERC20 && (wallets?.find((wallet) => wallet.walletId === selectedWallet.feeWalletId) as Wallet);

  const getNetworkFeeEstimation = async () => {
    const fee = await WalletTransactionService.getEstimateFee(
      Number(transactionRequest?.quantity),
      Number(selectedWallet.walletId),
      String(selectedDestinationAddress?.address),
    );
    setNetworkFeeEstimation(fee as EstimateFee);
    setIsLoading(false);
  };

  useEffect(() => {
    getNetworkFeeEstimation();
  }, []);

  return {
    isLoading,
    isERC20,
    networkFeeEstimation,
    networkFeeTicker,
    feeWallet,
  };
};

export default useGetNetworkFeeEstimation;
